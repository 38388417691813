import React from 'react'
import './Terms.css'

function Terms() {
  return (
    <div>
        <div className='terms'>
        <h2>Terms & Conditions:</h2>
        <p>Due to the rapidly changing circumstances due to COVID-19, airlines like Emirates, British Airways, and Gulf Air flying from London to Intl have implemented new flexible cancellation policies.</p>
        <h3>DEPOSITS & TICKETS ARE EITHER REFUNDABLE NOR CHANGEABLE (Terms & Conditions May Apply):</h3>
        <p>Unless Specified, All the deposits paid and tickets purchased/issued are non-refundable. In case of cancellation or no show (Failure to arrive at departure airport on time) and non-changeable before or after departure (date change is not permitted). Once flights reserved, bookings/tickets are non-transferable to any other person means that name changes are not permitted. Issued Tickets are also not re-routable. If you are reserving the flight by making the advance partial payment (Initial deposit) then please note that fare/taxes may increase at any time without the prior notice. It means the price is not guaranteed unless the ticket is issued because the airline/consolidator has the right to increase the price due to any reason. In that case, we will not be liable and the passenger has to pay the fare/tax difference. We always recommend you to pay ASAP and get issue your ticket to avoid this situation. Furthermore, if you will cancel your reservation due to any reason, then the paid deposit(s) will be non-refundable.</p>
        <h3>CHECKING ALL FLIGHT DETIALS & PASSENGER NAME(S):</h3>
        <p>It is your responsibility to check all the details are correct i.e. Passenger names (are same as appearing on passport/travel docs), Travelling dates, Transit Time, Origin & Destination, Stop Over, Baggage Allowance, and other flight information. Once the ticket is issued then no changes can be made, unless specified.</p>
        <h3>PASSPORT, VISA & IMMIGRATION REQUIREMENTS:</h3>
        <p>You are responsible for checking all these items like Passport, Visa (including Transit Visa), and other immigration requirements. You must consult with the relevant Embassy/Consulate, well before the departure time for up-to-date information as requirements may change from time to time. We regret we cannot accept the liability of any transit visa and if you are refused to board the flight or could not clear the immigration or any kind of failure in providing the information required like passport, visa or other documents required by any airline, authority or country.</p>
        <h3>RECONFIRMING RETURN/ONWARD FLIGHTS:</h3>
        <p>It is the traveler's responsibility to RECONFIRM your flights at least 72 hours before your departure time either with your travel agent or the relevant Airline directly. The company will not be liable for any additional costs due to your failure to reconfirm your flights.</p>
        <h3>Covid-19 restrictions</h3>
        <p>Due to Covid-19, Additional entry requirements have been introduced which varies from country to country and may be subject to change with short notice. You are responsible for checking and conforming with the entry and exit requirements at their origins and destinations. Requirements may include proof of negative PCR Covid-19 tests, temperature checks or completion of forms, etc. If the flight is affected due to Covid-19 then airline policies will be applied. To accommodate the traveler, it is quite possible that the airline only offers the future date change or credit voucher option instead of a refund. In that case, you must have to follow the airline rules and cannot demand a refund. If a full refund is permitted, A admin fee (Per person) will be deducted as the service charges and a refund can take up to 3 months. If the flight is operated by the airline and you decide not to board the flight, then you will be ineligible for a refund. In this case (if your ticket is refundable) then airline fare rules (cancellation fee) will be applied for processing the refunds.</p>
        <h4>Very Important:</h4>
        <p>Serene Travel, do not accept responsibility for any financial loss if the airline fails to operate. Passengers will be solely responsible for that so it is highly recommended that separate travel insurance must be arranged to protect yourself.</p>
        <h3>Reserving Your Holiday:</h3>
        <p>On receipt of your request and deposit we will confirm you booking and from that point cancellation charges will apply, and send you a confirmation with details of your arrangements. Please note that a telephone booking confirmation is as firmly confirmed as if it were made/confirmed in writing at that time.</p>
        <h3>Price Guarantee:</h3>
        <p>As scheduled airlines reserve the right to increase prices at any time the price shown on this confirmation invoice will ONLY be guaranteed once full payments is received before due date of payment. The payment of a deposit guarantees your seat, not the price.</p>
        <h3>Minor Changes To Your Holiday:</h3>
        <p>If we are obliged to make any minor change in the arrangements for your holiday we will inform you as soon as possible.</p>
        <h3>Cancellation After Ticket Issue:</h3>
        <p>Will result in loss of 100% of total cost of all travel arrangements in most cases. Please consult your reservation adviser. Charter flights carry a 100% cancellation fee both before and after ticket issue.</p>
        <h3>Complaints:</h3>
        <p>If you have a problem during your holiday, it is a legal requirement that you inform the property owner/hotel management/our local agent who will endeavor to resolve the situation. If your complaint cannot be sorted out locally you must obtain written confirmation that the complaint was lodged. You must follow this up within 28days of your return home in writing to us with all the relevant details. If you fail to follow this procedure, it may make it impossible to investigate your complaint fully.</p>
        <h3>Legal Jurisdiction:</h3>
        <p>We accept the jurisdiction of the Courts in any part of the UK in which the client is domiciled. For clients not domiciled in the UK the Court of England shall have sole jurisdiction.</p>
      </div>
    </div>
  )
}

export default Terms