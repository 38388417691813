import React from 'react'
import './About.css'
function About() {
  return (
    <div>
       <div className='about-us'>
        <div className='container-8'>
          <div className='row'>
            <div className='flex'>
              <h2>About Us</h2>
              <h3>Wellcome</h3>
              <p>Serene Travels is working as Marketing (Company number 13426216) Registered under UK Law. We offer competitive discounted worldwide travel. We assure that you will be highly satisfied with our timely and customer priority service. You can enjoy any of our hurdle free services in the UK within your budget. Travel products are available over the telephone, e-mail or live chat inquiry. We operate leading brands, including World-Span, Galileo, a global distribution system (GDS) and Fare Base, a Tell Me e-product known for air fares distribution and management specialist.Whatever it may be we provide our trusted service with several incredible and withstand features like cost-effective deals, book now and pay later service, expert customer service and quality for travel destinations throughout the world from almost all airports from the UK.
                
                
              </p>
            </div>
            <div className='flex'>
              <img src="https://w0.peakpx.com/wallpaper/636/414/HD-wallpaper-travel-agency.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
            <div className='container-9'>
              <div className='about-content'>
                <div className='image'>
                  <img src="https://img.freepik.com/free-photo/airport-terminal_1417-1456.jpg" alt="images-2"/>
                </div>
                <div className='content'>
                  <h2>What We Do</h2>
                  <p>We are providing services of flying tickets, reservation, hotel booking, last minute flights, group fares and family holidays etc. We provide best Customer Services, as our experienced and Professional Consultants are 24/7 available to assist you from your reservation to complete travelling outbound/inbound.
                  </p>
                  <h3>SereneTravel Team</h3>
                </div>
              </div>
            </div>
            <div className='about-end'>
        <div className='container-10'>
          <div className='row-1'>
            <div className='flex-1'>
              <h3>Our Mission</h3>
              <p>We truly believe that our chain of customers is the huge asset of our company. That’s why, our repeated passenger always choose us for their travelling all around the World.
              </p>
              <p>
              SereneTravels UK believes that travel is fundamentally important for every individual - in exploring              different destinations, to lead satisfying lives, to know different cultures and heritage and in building             reasonable, peaceful, firm and prosperous society. The Institute is enthusiastic about promoting              exploration in its broadest sense, for all, and supporting the individuals who make it conceivable.             Despite the fact that the bewildering achievement rates, which access has been presented each year bear             witness to on the elevated amounts are not simply measurements or cases to us. At Access, we function as          committed facilitators who assemble extensions in the middle of desires and accomplishments. In any case,           we are substantially more than simply these service suppliers. To this end, our central goal is to             provide the customer satisfaction with the best travel experience to worldwide destinations and to spread            your wings within your budget.
                
                
              </p>
            </div>
            <div className='flex'>
              <img src="https://ychef.files.bbci.co.uk/976x549/p01z123q.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About