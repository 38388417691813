import React from 'react'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {BsTelephoneFill} from 'react-icons/bs'
import {MdEmail} from 'react-icons/md'
import './Contact.css'

function Contact() {
  return (
    <div>
      <section className='contact'>
      <div className='container-11'>
        <div className='contact-info'>
          <div className='box'>
            <div className='icon'><FaMapMarkerAlt/></div>
            <div className='text'>
              <h3>Address</h3>
              <p>20-22 Wenlock Road, London,<br/> England, N1 7GU.</p>
            </div>
          </div>
          <div className='box'>
            <div className='icon'><BsTelephoneFill/></div>
            <div className='text'>
              <h3>Phone</h3>
              <p>02080901915</p>
            </div>
          </div>
          <div className='box'>
            <div className='icon'><MdEmail/></div>
            <div className='text'>
              <h3>Email</h3>
              <p>info@silklinetravels.co.uk</p>
            </div>
          </div>
        </div>
        <div className='contact-form'>
          <form>
            <h1>Send Message</h1>
            <div className='input-box'>
              <input type="text"  required='required'/>
              <span>Full Name</span>
            </div>
            <div className='input-box'>
              <input type="text"  required='required'/>
              <span>Email</span>
            </div>
            <div className='input-box'>
              <textarea required='required'/>
              <span>Type Message Here....</span>
            </div>
            
            <div className='input-box'>
             <input type='submit' name='' value='send'/>
            </div>
          </form>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Contact