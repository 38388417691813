import React from 'react'
import {Routes,Route,Navigate} from 'react-router-dom'
import Home from '../Pages/Home/Home'
import About from '../Pages/About/About'
import Contact from '../Pages/Contact/Contact'
import Terms from '../Pages/Terms/Terms'
import Result from '../Pages/Result/Result'

function Router() {
  return (
    <div>
        <Routes>
        <Route path='/' element={<Navigate to='/home'/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/terms' element={<Terms/>}/>
            <Route path='/result' element={<Result/>}/>
        </Routes>

    </div>
  )
}

export default Router