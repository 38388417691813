import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'


function Header() {
  return (
    <>
       <div className='header'>
         <div className='container'>
            <div className='logo'>
                <h1><span>2-WAY</span>Travel</h1>
            </div>
            
            <div className='number'>
            <img src="./images/call.png" alt="Contact"  />
            <i><a href="https://api.whatsapp.com/send?phone=447440450351" ></a>+447440450351</i>
           
            </div>
            </div>
            <div className='names'>
              <ul>
               <Link className='link' to='/'> <li className='active'>Home</li></Link>
              <Link className='link' to='/about' > <li>About Us</li></Link>
            <Link className='link' to='/contact'><li>Contact Us</li></Link>
              </ul>
            </div>
       </div>
    </>
  )
}

export default Header