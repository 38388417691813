import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import {ImLocation2} from 'react-icons/im'
import {BsTelephoneFill,BsFacebook,BsFillEnvelopeAtFill} from 'react-icons/bs'
import {FiMail} from 'react-icons/fi'
import {AiFillLinkedin,AiFillInstagram} from 'react-icons/ai'

function Footer() {
  return (
    <>
    <div className='container-4'>
      <div className='email'>
        <input type="email" text-color='black' placeholder='Enter Your Email....'/>
        <div className='icon'>
          <BsFillEnvelopeAtFill/>
        </div>
        <button type='submit'>Subscribe</button>
      </div>
    </div>
    <div className='footer'>
      <div className='container'>
        <div className='box'>
          <div className='col-1'>
          <h1 style={{color:"brown"}}><span style={{color:"white"}}>2-WAY</span> Travel</h1>
            <p>Flights to worldwide destinations from all major airports of UK on all major airlines. We try to ensure your travel within your budget!</p>
            <img src="https://freepngimg.com/save/25504-major-credit-card-logo-transparent-background/1532x134" alt="cards" />
            <p>Booking Fee From £15-£25, Terms & conditions apply.</p>
          </div>
          <div className='col-2'>
            <h3>OUR SERVICES</h3>
            <ul>
            <li><Link className='link' to='/'>- HOME</Link></li>
            <li><Link className='link' to='/about'>- About us</Link></li>
            <li><Link className='link' to='/contact'>- Contact us</Link></li>
            <li><Link className='link' to='/terms'>- Terms & Conditions</Link></li>
            
            </ul>
          </div>
          <div className='col-4'>
            <h3>Contact Info</h3>
            <div className='icon'>
              <ImLocation2/>
            <a href='/'><p>20-22 Wenlock Road, London, England, N1 7GU.</p></a>
            </div>
            <div className='icon'>
              <BsTelephoneFill/>
            <a href='tel:02080901915'>02080901915</a>
            </div>
            <div className='icon'>
              <FiMail/>
             <a href='mailto:info@serenetravels.co.uk'><p>info@serenetravels.co.uk</p></a>
            </div>
            <div className='main-icon'>
            <div className='icon-1'>
            <BsFacebook/>
            </div>
            <div className='icon-2'>
            <AiFillLinkedin/>
            </div>
            <div className='icon-3'>
            <AiFillInstagram/>
            </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div className='row'>
        <div className='col'>
          <p>&#169;2024 2-Way Travel & Tour.All Right Reserved</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Footer