import React, { useState, useRef, useEffect} from 'react';
import './Home.css';
import { TiTick } from "react-icons/ti";
import { MdDateRange } from "react-icons/md";
import { FaEnvelope,FaPhoneAlt  } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import emailjs from 'emailjs-com';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { TbPlaneDeparture } from "react-icons/tb";
import { LuPlaneLanding } from "react-icons/lu";
// import {Link} from 'react-router-dom'


function Home() {
  const [isRoundTrip, setIsRoundTrip] = useState(true);
  const [passengerDetails, setPassengerDetails] = useState('');
  const [passengerCounts, setPassengerCounts] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });
  const [activePassengerOptions, setActivePassengerOptions] = useState(false);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [airports, setAirports] = useState([]);
  const [filteredFromResults, setFilteredFromResults] = useState([]);
  const [filteredToResults, setFilteredToResults] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false); // New state variable

  const form = useRef();

  const handleFilter = (value, setFunction, setFilterResults) => {
    if (Array.isArray(airports)) {
      const results = airports.filter(airport =>
        airport.iata_code.toLowerCase().includes(value.toLowerCase()) ||
        airport.name.toLowerCase().includes(value.toLowerCase()) ||
        airport.country.toLowerCase().includes(value.toLowerCase())
      );
      setFilterResults(results);
    }
    setFunction(value);
  };

  const handleKeyDown = (e, setFunction, filterResults, setFilterResults) => {
    if (e.key === 'Enter' && selectedIndex >= 0) {
      setFunction(`${filterResults[selectedIndex].iata_code} - ${filterResults[selectedIndex].name}, ${filterResults[selectedIndex].country}`);
      setFilterResults([]);
      setSelectedIndex(-1);
    } else if (e.key === 'ArrowDown' && selectedIndex < filterResults.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else if (e.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleClick = (index, setFunction, filterResults, setFilterResults) => {
    setFunction(`${filterResults[index].iata_code} - ${filterResults[index].name}, ${filterResults[index].country}`);
    setFilterResults([]);
  };

  const handleRoundTripCheckboxChange = () => {
    setIsRoundTrip(true);
  };

  const handleOneWayCheckboxChange = () => {
    setIsRoundTrip(false);
    setReturnDate(null);
  };

  const handlePassengerSelectChange = (type, count) => {
    setPassengerCounts(prevCounts => ({
      ...prevCounts,
      [type]: count,
    }));
  };

  useEffect(() => {
    const details = [];
    if (passengerCounts.adults > 0) {
      details.push(`${passengerCounts.adults} Adult(s)`);
    }
    if (passengerCounts.children > 0) {
      details.push(`${passengerCounts.children} Child(ren)`);
    }
    if (passengerCounts.infants > 0) {
      details.push(`${passengerCounts.infants} Infant(s)`);
    }
    setPassengerDetails(details.join(', '));
  }, [passengerCounts]);

  useEffect(() => {
    axios.get('http://localhost:3001/getairports')
      .then(response => {
        console.log('Data fetched:', response.data);
        setAirports(response.data);
      })
      .catch(err => console.error('Error fetching data:', err));
  }, []);

  useEffect(() => {
    emailjs.init("bnhCHaCSInZtCLQ58");
  }, []);

  const validateForm = () => {
    if (!from || !to || !departureDate || (isRoundTrip && !returnDate) || !name || !number || !email) {
      return false;
    }
    return true;
  };

  // const handleBookNowClick = (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     setShowAdditionalInputs(true);
  //   } else {
  //     alert("Please fill out all required fields before proceeding.");
  //   }
  // };

  const sendMail = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      alert("Please fill out all required fields before submitting.");
      return;
    }

    try {
      const params = {
        from: from,
        to: to,
        departure: departureDate instanceof Date ? departureDate.toLocaleDateString() : '',
        return: returnDate instanceof Date ? returnDate.toLocaleDateString() : '',
        passenger: passengerDetails,
        name: name,
        number: number,
        email: email
      };

      const serviceID = "service_6fcd1jw";
      const templateID = "template_46jroza";

      const response = await emailjs.send(serviceID, templateID, params);
      console.log(response);

      alert("Email sent Successfully");
    } catch (error) {
      console.error("Error sending email:", error);
    }

    const formData = {
      from: from,
      to: to,
      departure: departureDate instanceof Date ? departureDate.toISOString() : '',
      return: returnDate instanceof Date ? returnDate.toISOString() : '',
      passenger: passengerDetails,
      name: name,
      number: number,
      email: email
    };

    console.log('Storing formData in localStorage:', formData);
    localStorage.setItem('formData', JSON.stringify(formData));

    navigate('/result');

  };
  const handleDateInputClick = (inputType) => {
    setShowAdditionalInputs(true);
    console.log(`${inputType} date input clicked`);
};
  
  return (
    <div className='home'>
      <div className='container'>
        <div className='img'>
          <div className='form-container'>
          <form className='form' id='form' ref={form} onSubmit={sendMail}>
              <div className='button-top'>
                <label>
                  <input style={{marginRight:5,alignItems:"center"}} type='radio' checked={isRoundTrip} onChange={handleRoundTripCheckboxChange} />
                  Round Trip
                </label>
                <label>
                  <input style={{marginRight:5}} type='radio' checked={!isRoundTrip} onChange={handleOneWayCheckboxChange} />
                  One Way
                </label>
              </div>
              <div className='colum-mm'>
                <div className='input-box-mm'>
                <TbPlaneDeparture className='icon-1' />
                  <input
                    type="text"
                    placeholder='From'
                    id='from'
                    value={from}
                    required
                    onChange={e => handleFilter(e.target.value, setFrom, setFilteredFromResults)}
                    onKeyDown={e => handleKeyDown(e, setFrom, filteredFromResults, setFilteredFromResults)}
                  />
                   <div className='search-result' style={{padding:10,maxHeight:20}}>
                    {filteredFromResults.slice(0, 10).map((d, i) => (
                      <div
                        key={i}
                        className={selectedIndex === i ? 'selected' : ''}
                        onClick={() => handleClick(i, setFrom, filteredFromResults, setFilteredFromResults)}
                      >
                        {d.iata_code} - {d.name}, {d.country}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='input-box-mm'>
                <LuPlaneLanding className='icon-1' />
                  <input
                    type="text"
                    placeholder='To'
                    id='to'
                    name='to'
                    required
                    value={to}
                    onChange={e => handleFilter(e.target.value, setTo, setFilteredToResults)}
                    onKeyDown={e => handleKeyDown(e, setTo, filteredToResults, setFilteredToResults)}
                  />
                  <div className='search-result' style={{padding:20}}>
                    {filteredToResults.map((d, i) => (
                      <div
                        key={i}
                        className={selectedIndex === i ? 'selected' : ''}
                        onClick={() => handleClick(i, setTo, filteredToResults, setFilteredToResults)}
                      >
                        {d.iata_code} - {d.name}, {d.country}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='input-box-mm'>
                <MdDateRange className='icon-1' />
                <div className="datepicker-container">
          <DatePicker
            required
            selected={departureDate}
            onFocus={() => handleDateInputClick('departure')}
            onChange={(date) => setDepartureDate(date)}
            placeholderText="Depart"
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
          />
        </div>
                </div>
                {isRoundTrip && (
                  <div className='input-box-mm'>
                    <MdDateRange className='icon-1' />
                    <div className="datepicker-container">
          <DatePicker
            required
            selected={returnDate}
            onFocus={() => handleDateInputClick('departure')}
            onChange={(date) => setReturnDate(date)}
            placeholderText="Return"
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
          />
        </div>
                  </div>
                )}
                {!isRoundTrip && (
                  <div className='input-box-mm'>
                      <IoPersonSharp className='icon-1' />
                    <input
                      type="text"
                      name='name'
                      required
                      id='name'
                      placeholder='Enter Full Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className='colum'>
                <div className='select-box'>
                
{showAdditionalInputs && (
  <>
  <div className='input-box-mm'>
  <FaEnvelope className='icon' />
  <input
    type="email"
    name='email'
    required
    id='email'
    placeholder='Enter Email'
    value={email}
    onChange={(e) => setEmail(e.target.value)}
  />
</div>
{isRoundTrip && (
                  <div className='input-box-mm'>
                      <IoPersonSharp className='icon-1' />
                    <input
                      type="text"
                      name='name'
                      required
                      id='name'
                      placeholder='Enter Full Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                )}
   
    <div className='input-box-mm'>
      <FaPhoneAlt className='icon' />
      <input
        type="text"
        required
        value={number}
        name='number'
        id='phone'
        placeholder='Enter Phone Number'
        onChange={(e) => setNumber(e.target.value)}
      />
    </div>
    <div className='input-box-mm passenger-input-container'>
      <FaPerson className='icon-2' />
      <input
        type="text"
        required
        style={{marginLeft:-14}}
        name="passenger"
        id="passenger"
        placeholder="Passenger Details"
        value={passengerDetails}
        readOnly
        onClick={() => setActivePassengerOptions(true)}
      />
      <div className={`passenger-options ${activePassengerOptions ? 'active' : ''}`} >
        <div className="option">
          <label style={{ color: "white" }}>Adults:</label>
          <select value={passengerCounts.adults} onChange={(e) => handlePassengerSelectChange('adults', parseInt(e.target.value))}>
            {Array.from({ length: 10 }, (_, i) => i + 1).map((count) => (
              <option style={{ backgroundColor: "black", color: "white", width: 20 }} key={count} value={count}>{count}</option>
            ))}
          </select>
        </div>
        <div className="option">
          <label style={{ color: "white" }}>Children:</label>
          <select value={passengerCounts.children} onChange={(e) => handlePassengerSelectChange('children', parseInt(e.target.value))}>
            {Array.from({ length: 10 }, (_, i) => i).map((count) => (
              <option style={{ backgroundColor: "black", color: "white" }} key={count} value={count}>{count}</option>
            ))}
          </select>
        </div>
        <div className="option">
          <label style={{ color: "white" }}>Infants:</label>
          <select value={passengerCounts.infants} onChange={(e) => handlePassengerSelectChange('infants', parseInt(e.target.value))}>
            {Array.from({ length: 10 }, (_, i) => i).map((count) => (
              <option style={{ backgroundColor: "black", color: "white" }} key={count} value={count}>{count}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </>
)}
                </div>
              </div>
              <div className='data-mm'>
                <div className='input-name-1'>
                  <input type="submit" value="Book Now" className="button"  />
                </div>
              </div>
            </form>
          </div>
          <div className='book'>
        <div className='one'>
          <TiTick style={{ color: "black" }} />
          <h2>Risk Free Purchase</h2>
          <p>We provide the most valued Financial Protected Tickets to our customers.</p>
        </div>
        <div className='one'>
          <TiTick style={{ color: "black" }} />
          <h2>We Love Talking</h2>
          <p>We always stay in touch with our customers for their better assistance.</p>
        </div>
        <div className='one'>
          <TiTick style={{ color: "black" }} />
          <h2>Comparative Fare</h2>
          <p>We deal in more than 300 airlines and we provide comparatively low fares.</p>
        </div>
        <div className='one'>
          <TiTick style={{ color: "black" }} />
          <h2>Cheapest Fare</h2>
          <p>We always provide the cheapest price tickets from the market.</p>
        </div>
      </div>
        </div>
        <img src="./images/home image.jpg" alt="imag" />
      </div>
      {/* <div className='banner'>
        <div className='container'>
          <div className='detail'>
            <div className="contact-1">
              <h1>Need help booking? Our agents are ready!</h1>
              <p>Call us 24/7 at +44 744 045 0351</p>
            </div>
            <div className='img'>
              <div className='klm'>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9SVUm1eBGRWbK8RkxACxMMy5gi3e6ySJ1D9agWqFf5A&s" alt='logo 1' />
                <p>KLM Airline</p>
              </div>
              <div className='british'>
                <img src="https://airhex.com/images/airline-logos/air-france.png" alt='logo 2' />
                <p>AirFrance Airline</p>
              </div>
              <div className='airfrance'>
                <img className='airfrance' src="https://i.pinimg.com/736x/93/a6/9e/93a69e97166c4b96ae10ad4c7697ad1f.jpg" alt='logo 3' />
                <p>British Airline</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      <div className='cards'>
      <h1>Top Destination</h1>
        <div className='container'>
          <div className='card' href="/freetown">
            <img className='img-card' src="./images/lagos 1.jpg" alt="img" />
            <h1 className='card-title'>Cheap Flight To Accra</h1>
            <hr />
            <p className='card-description'>Starting From £510</p>
            <a className='card-btn' href="/">Check Now</a>
          </div>
          <div className='card'>
            <img className='img-card' src="./images/kinshasa 1.jpg" alt="img" />
            <h1 className='card-title'>Cheap Flight To Entebbe</h1>
            <hr />
            <p className='card-description'>Starting From £448</p>
            <a className='card-btn' href="/">Check Now</a>
          </div>
          <div className='card'>
            <img className='img-card' src="./images/kinshasa 2.jpg" alt="img" />
            <h1 className='card-title'>Cheap Flight To Freetown</h1>
            <hr />
            <p className='card-description'>Starting From £490</p>
            <a className='card-btn' href="/">Check Now</a>
          </div>
        </div>
        <div className='container'>
          <div className='card'>
            <img className='img-card' src="./images/kinshasa 3.jpg" alt="img" />
            <h1 className='card-title'>Cheap Flight To Lagos</h1>
            <hr />
            <p className='card-description'>Starting From £510</p>
            <a className='card-btn' href="/">Check Now</a>
          </div>
          <div className='card'>
            <img className='img-card' src="./images/kinshasa 4.jpg" alt="img" />
            <h1 className='card-title'>Cheap Flight To Harare</h1>
            <hr />
            <p className='card-description'>Starting From £570</p>
            <a className='card-btn' href="/">Check Now</a>
          </div>
          <div className='card'>
            <img className='img-card' src="./images/kinshasa.jpg" alt="img" />
            <h1 className='card-title'>Cheap Flight To Kinshasa</h1>
            <hr />
            <p className='card-description'>Starting From £470</p>
            <a className='card-btn' href="/">Check Now</a>
          </div>
        </div>
      </div>



{/*       
      <div className='newsletter'>
        <div className='container'>
          <div className='letter'>
            <i><FaEnvelope /></i>
            <h1>Get Updates and More</h1>
          </div>
          <div className='sign'>
            <div className='input'>
              <input type="email" placeholder='Email Address' />
            </div>
            <div className='data-mm'>
                <div className='input-name-1'>
                  <input type="submit" value="Book Now" className="button"  />
                </div>
              </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Home;
