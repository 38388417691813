import React from 'react'
import './Result.css'

function Result() {
  return (
    <div>
        <div className='result'>
            <div className='container'>
                <div className='detail'>
                    <h1 style={{fontSize:20}}>Your Flight inquiry has been received, one of our agent will call you soon, Thank you for Inquiring with Us.</h1>
                </div>
                </div>
        </div>
    </div>
  )
}

export default Result