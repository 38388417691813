import './App.css';
import Layout from './Components/Layout/Layout';
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
      <Layout/>
    </div>
  );
}

export default App;
